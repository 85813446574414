import * as Dialog from '@radix-ui/react-dialog'
import { ICONS_SOCIAL } from '~/core/constants/social'
import { Button } from '~/core/ui/Button'
import Image from 'next/image'
import { t } from 'i18next'
import Link from 'next/link'
import { i18n } from 'next-i18next'
import pathConfiguration from 'src/configuration/path'
import { URL_REGISTER_APP, URL_LOGIN_APP } from '~/core/constants/url'
import { useState } from 'react'
import { NavItemsProps } from './NavigationBar'
import { ICONS } from '~/core/constants/resources'
import IconWrapper from '~/core/ui/IconWrapper'
import SubItem from './NavigationBar/SubItem'

const MobileNavigation = ({ navList }: { navList: NavItemsProps }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Dialog.Root onOpenChange={(open) => setOpen(open)}>
      <Dialog.Trigger asChild>
        <IconWrapper
          name="AlignJustify"
          className="tablet:hidden bg-transparent p-0 bg-none px-0 py-0 !h-8"
        />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content
          className="z-[100] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] h-full w-full translate-x-[-50%] translate-y-[-50%] bg-white focus:outline-none"
          aria-describedby={undefined}>
          <Dialog.Title className="px-4 py-6 flex justify-between items-center border-b">
            <Image
              src={ICONS_SOCIAL?.hireforce_text}
              width={132}
              height={0}
              alt="hireforce-logo-mobile"
              className="h-8"
              quality={100}
            />
            <Dialog.Close asChild>
              <IconWrapper
                name="X"
                className="tablet:hidden bg-transparent p-0 bg-none focus:ring-0 focus:ring-white text-gray-500"
              />
            </Dialog.Close>
          </Dialog.Title>
          <div className="relative w-full h-full flex flex-col overflow-y-auto">
            <div>{isOpen && <CollapseNav navList={navList} />}</div>

            <div className="flex flex-col items-center gap-6 px-4 h-64">
              <Link
                href={URL_REGISTER_APP}
                className="font-medium text-[17px] text-gray-900 w-fit">
                {`${t('lp:try_for_free')}`}
              </Link>
              <Link
                href={URL_LOGIN_APP}
                className="font-medium text-[17px] text-gray-900">
                {`${t('lp:sign_in')}`}
              </Link>
              <Link
                href={pathConfiguration.contactRequest}
                locale={i18n?.language}
                className="w-full">
                <Button
                  size="md"
                  label={`${t('lp:book_a_demo')}`}
                  type="quaternary"
                  className="w-full"
                />
              </Link>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const CollapseNav = ({ navList }: { navList: NavItemsProps }) => {
  const [expanded, setExpanded] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <div className="p-4 h-full overflow-scroll">
      {navList.map((item, index) => {
        const key = item.label
          .replace(/\s+/g, '-')
          .toLowerCase()
          .concat(`-${index}`)
        const hasSub = item.subItems && item.subItems?.length > 0

        if (hasSub)
          return (
            <div key={key} className="flex flex-col">
              <button
                id={`nav-parent${index + 1}`}
                type="button"
                className="flex justify-between items-center w-full font-medium text-lg text-gray-900 py-3 border-b"
                onClick={() => toggleAccordion(index)}
                aria-expanded={expanded === index}
                aria-controls={`nav-child${index + 1}`}>
                {t(`lp:feature_section:item_title_${index + 1}`)}
                <Image
                  src={ICONS.chevronDown}
                  width={0}
                  height={0}
                  alt={`chevronDown${index}`}
                  className={`w-3 h-auto transition-all transform ${index === expanded && '-rotate-180'}`}
                />
              </button>

              <div
                id={`nav-child${index + 1}`}
                role="region"
                aria-labelledby={`nav-parent${index + 1}`}
                className={`max-w-2xl mt-1 grid text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                  expanded === index
                    ? 'grid-rows-[1fr] opacity-100'
                    : 'grid-rows-[0fr] opacity-0'
                }`}>
                <div className="overflow-hidden">
                  {item.subItems?.map((subItem, subIndex) => (
                    <Link
                      href={subItem.href}
                      target={item.target || '_blank'}
                      key={subIndex}
                      className={`block py-2 hover:bg-gray-100 rounded-lg`}>
                      <div className="flex gap-3 items-center">
                        {subItem.icon && (
                          <Image
                            src={`/img/feature/lp/${subItem.icon}.svg`}
                            alt={`nab-sub-${subIndex}`}
                            width={36}
                            height={36}
                          />
                        )}
                        <div className="flex flex-col">
                          <span className="font-medium text-base leading-6">{`${t('lp:' + subItem.label)}`}</span>
                          {subItem.subLabel && (
                            <span className="text-[13px] leading-[20px] text-gray-700">{`${t('lp:' + subItem.subLabel)}`}</span>
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                  {item.rightContent ? (
                    <div className="w-[344px] bg-primary-50 bg-opacity-20 pt-5">
                      {item.rightContent.title ? (
                        <p className="text-primary-400 text-base px-6">
                          {t('lp:' + item.rightContent.title)}
                        </p>
                      ) : null}
                      <div className="p-2">
                        {item.rightContent.subItems.map((subItem, subIndex) => (
                          <SubItem
                            key={subIndex}
                            subItem={subItem}
                            target={item.target}
                            smallText
                          />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
        else
          return (
            <div key={key}>
              <Link
                href={item.href}
                target={item.target || '_blank'}
                className="block font-medium text-lg text-gray-900 py-3 border-b">
                {`${t('lp:' + item.label)}`}
              </Link>
            </div>
          )
      })}
    </div>
  )
}

export default MobileNavigation
